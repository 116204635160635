<template>
  <div>
    <v-expansion-panels focusable>
      <v-expansion-panel v-for="(pro,i) in this.programs" :key="i">
        <v-expansion-panel-header>{{"برنامج الدوام "}} {{pro.className}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- ************************ start Update Program******************************* -->
          <v-dialog
            v-model="dialog"
            fullscreen
            ref="di"
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template template v-slot:activator="{ on }">
              <div class="text-center ma-2">
                <v-btn color="blue-grey" dark v-on="on">تعديل البرنامج</v-btn>
              </div>
            </template>
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{pro.className}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="updatePro(pro)">حفظ</v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-data-table :headers="detailsHeaders" :items="pro.details" class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>الحصص الدراسية</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="innerDialog" max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">حصة جديدة</v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">حصة جديدة</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-form ref="form">
                              <v-row class="mb-6">
                                <v-col>
                                  <v-select
                                    :items="['السبت', 'الأحد', 'الاثنين', 'الثلاثاء','الأربعاء','الخميس','الجمعة']"
                                    label="اليوم"
                                    v-model="detail.day"
                                    required
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-autocomplete
                                    v-model="detail.subjectId"
                                    :items="subjects"
                                    label="المادة"
                                    item-text="name"
                                    item-value="id"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    label="من الساعة"
                                    value="08:00"
                                    type="time"
                                    v-model="detail.fromHour"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    label="إلى  الساعة"
                                    value="08:00"
                                    type="time"
                                    v-model="detail.toHour"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="addDetail(pro.id)">حفظ</v-btn>
                          <v-btn color="blue darken-1" text @click="innerDialog = false">إغلاق</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteProDetail(item,pro.id)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary">لم يتم تحديد برنامج لهذه الشعبة</v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-dialog>
          <!-- ************************ end Update Program********************************** -->

          <!-- ************************ start Browse Program Details******************************* -->
          <v-data-table
            :headers="mainDetailHeader"
            :items="pro.details"
            item-key="id"
            group-by="day"
            class="elevation-1"
          ></v-data-table>

          <!-- ************************ End Browse Program Details******************************* -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { APIService } from "../../services/apiServices";
const apiService = new APIService();
export default {
  data() {
    return {
      dialog: false,
      innerDialog: false,
      detail: {
        day: "",
        subjectId: 0,
        subjectName: "",
        fromHour: "00:00",
        toHour: "00:00"
      },
      detailsHeaders: [
        {
          text: "اليوم",
          align: "start",
          value: "dayName"
        },
        {
          text: "المادة",
          align: "start",
          value: "subjectName"
        },
        { text: "من الساعة", value: "fromHour" },
        { text: "إلى الساعة", value: "toHour" },
        { text: "عملية الحذف", value: "actions" }
      ],
      mainDetailHeader: [
        {
          text: "اليوم",
          align: "start",

          value: "dayName"
        },
        {
          text: "المادة",
          align: "start",
          value: "subjectName"
        },
        { text: "من الساعة", value: "fromHour" },
        { text: "إلى الساعة", value: "toHour" }
      ],

      programs: [],
      program: {},
      subjects: [],
      subject: null
    };
  },
  methods: {
    getPrograms() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getThisYearTimePro()
        .then(response => {
          that.isGettingData = false;
          this.programs = response.programs;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    getSubjects() {
      apiService
        .getSubjects()
        .then(response => {
          this.subjects = response.subjects;
        })
        .catch(error => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
    addDetail(id) {
      var d = this.detail;
      d.subjectName = this.subjects.find(
        s => s.id === this.detail.subjectId
      ).name;
      this.programs.find(c => c.id === id).details.push(d);
      this.detail = {
        day: "",
        subjectName: "",
        fromHour: "00:00",
        toHour: "00:00"
      };

      this.innerDialog = false;
    },
    deleteProDetail(item, proId) {
      if (confirm("هل تريد بالتأكيد حذف الحصة")) {
        const index = this.programs
          .find(c => c.id === proId)
          .details.indexOf(item);
        if (index > -1)
          this.programs.find(c => c.id === proId).details.splice(index, 1);
      }
    },
    updatePro(pro) {
      setTimeout(() => {
        apiService
          .updateTimeProgramDetail(pro)
          .then(() => {
            this.dialog = false;
          })
          .catch(error => {
            this.error = "حدث خطأ ";
            alert(error);
            this.failed();
          });
      }, 3000);
    },
    showdetailDialog(pro) {
      this.prgram = pro;
      this.dialog = true;
    }
  },
  created() {
    this.getPrograms();
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getSubjects();
      }
    }
  }
};
</script>
